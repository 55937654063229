<template>
  <v-container id="data-tables-view" fluid tag="section">
    <!-- <v-col class="text-right" v-if="canDeleteCsvErrors">
      <app-btn @click="dialogDelete = true">
        <v-icon>mdi-view-grid-plus</v-icon> Clear Logs
      </app-btn>
    </v-col> -->
    <material-card
      icon="mdi-clipboard-list"
      icon-small
      color="primary"
      title="Logs"
    >
      <v-card-text>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          class="ml-auto"
          hide-details
          label="Search records"
          single-line
          style="max-width: 250px"
        />

        <v-divider class="mt-3" />

        <v-data-table
          :headers="headers"
          :items="logs"
          :options.sync="options"
          :search.sync="search"
          :server-items-length="totalLogs"
          :loading="loading"
          :footer-props="{
            showFirstLastPage: true,
            'items-per-page-options': [5, 10, 15],
          }"
        >
          <template v-slot:[`item.action`]="{ item }">
            <div>{{ actionType(item.action) }}</div>
          </template>
          <template v-slot:[`item.data`]="{ item }">
            <div>
              <v-icon
                color="error"
                v-if="item.data && item.data.toLowerCase().includes('failed')"
              >
                mdi-close-circle </v-icon
              >{{ item.data }}
            </div>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            <div>{{ dateFormat(item.createdAt) }}</div>
          </template>
        </v-data-table>
      </v-card-text>
    </material-card>
  </v-container>
</template>
<script>
import AnalysisDataService from "../services/AnalysisDataService";
import moment from "moment";
export default {
  name: "CsvErrors",
  data: () => ({
    permissions: localStorage.getItem("permissions"),
    dialogDelete: false,
    options: {},
    loading: false,
    totalLogs: 0,
    search: "",
    headers: [
      {
        text: "ActionOn",
        value: "actionOn",
      },
      {
        text: "Action",
        value: "action",
      },
      {
        text: "Data Id",
        value: "dataId",
      },
      {
        text: "Data",
        value: "data",
      },
      {
        text: "CreatedBy",
        value: "createdBy.userName",
      },
      { text: "CreatedAt", value: "createdAt" },
    ],
    logs: [],

    search: "",
    awaitingSearch: null,
  }),

  watch: {
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    search: {
      handler() {
        clearTimeout(this.awaitingSearch);
        this.awaitingSearch = setTimeout(() => {
          this.initialize();
        }, 2000);
      },
    },
  },

  methods: {
    initialize() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      this.$http
        .post("Logs/Search", {
          pageNumber: page,
          pageSize: itemsPerPage,
          search: this.search,
        })
        .then((response) => {
          this.logs = response.data.data.items;
          this.totalLogs = response.data.data.totalCount;
          this.loading = false;
        })
        .catch(() => {
          this.logs = [];
          this.totalLogs = 0;
          this.loading = false;
        });
    },
    actionType(type) {
      switch (type) {
        case 1:
          return "Create";
        case 2:
          return "Update";
        case 3:
          return "Delete";
        case 4:
          return "Read";
        default:
          return "";
      }
    },
    dateFormat(date) {
      if (date) {
        return moment.utc(date).local().format("h:mm:ss a DD.MM.YYYY");
      } else {
        return "";
      }
    },
  },
};
</script>
